.App--brantleygilbert {
  background: url('./images/gallery-background.jpg') no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  .Header {
    background-color: #000000;
    color: var(--white);

    .CartCount__Icon {
      color: var(--white);
    }

    &__Buttons {
      .Button {
        background-color: #D3BB92;
        border-color: #D3BB92;
      }
    }
  }

  .ScheduleItemListItem {
    color: var(--white);

    &--closest {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .Footer {
    background: #000000;
    opacity: 0.7;
  }
}

