.ScheduleItemList {
  --header-height-adjust: 44px;

  padding-top: calc(var(--header-height) - var(--header-height-adjust));
  padding-bottom: calc(var(--footer-height) * 2);

  .Header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
  }

  &__Main {
    max-width: 850px;
    width: 100%;
    margin: 0 auto 0;
  }

  @media (min-width: 700px) {
    --header-height-adjust: 48px;
  }
}

  .ScheduleItemListItem {
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    cursor: pointer;

    color: var(--default-text);
    text-decoration: none;
    padding: 17px 12px;

    border-top: 1px solid var(--gray-20);

    &:last-of-type {
      border-bottom: 1px solid var(--gray-20);
    }

    .Button {
      background: var(--gray-60);
      border-color: var(--gray-60);
      color: var(--white);

      margin: 0;
      padding: 0;
      border-radius: 15px;
      text-transform: uppercase;
      letter-spacing: 0.25px;

      min-width: 80px;
      font-size: var(--ui-size-tiny);
      line-height: 24px;
    }

    &--register .Button {
      border-color: var(--gray-40);
      background-color: var(--white);
      color: var(--gray-60);
    }

    &--registered .Button {
      background: var(--gray-10);
      border-color: var(--gray-10);
      color: var(--gray-60);
    }

    &--closest {
      background-color: var(--gray-0);
    }

    &__Date {
      font-weight: bolder;
    }

    &__Time {
      display: block; 
      color: var(--gray-40);
      font-weight: normal;
      white-space: nowrap;
    }

    &__Info {
      flex-grow: 1;
    }

    &__Location {
      font-weight: bolder;
    }

    @media(min-width: 360px) {
      &__Info {
        display: flex;
        align-items: flex-start;
      }

      &__Date {
        width: 40%;
      }

      &__Text {
        flex: 1 1 60%;
      }

      .Button {
        line-height: 26px;
        min-width: 100px;
        font-size: var(--ui-size-small);
      }
    }

  @media(min-width: 700px) {
    line-height: 28px;

    &__Text {
      display: flex;
    }

    &__Date {
      width: 25%;
      font-weight: normal;
    }

    &__Location {
      width: 45%;
      font-weight: normal;
    }

    &__Description {
      flex: 1 1 55%;
    }

    .Button {
      line-height: 28px;
      min-width: 110px;
    }
  }
}
