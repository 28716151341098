.App--vandyboys {
  .Header {
    background: url('./images/gallery-header.jpg') no-repeat, #1a1a1a;
    background-size: auto 100%, cover;
    background-position: center;

    color: transparent;

    &__Logo {
      opacity: 0;
      flex-grow: 1;
      width: 100%;
    }

    .CartCount__Icon {
      color: var(--white);
    }

    &__Buttons .Button {
      display: none;
    }
  }
}
