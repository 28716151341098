.Image {
  position: relative;
  transition: opacity 0.1s;
  transition-delay: 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &--loading {
    opacity: 0.5;
  }

  &--hiddenWhileLoading {
    transition: opacity 0s 0s;

    &.Image--loading {
      opacity: 0;
    }
  }
}
