.SquareContainer {
  position: relative;
  display: flex;
  justify-content: space-around;
  background-color: var(--gray-0);

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.SquareContainer__Contents {
  position: absolute;
  width: 100%;
  height: 100%;
}
