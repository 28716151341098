.App--kimpetras {
  --ui-header-background: #000000;
  --ui-header-border: 2px solid #E83088;
  --ui-header-text-color: var(--white);
  --ui-page-background: url('./images/gallery-background.jpg') top center/100% auto fixed;

  .Header {
    &__Name {
      display: none;
    }

    &__Logo {
      --logo-height: 125px;

      @media (min-width: 700px) {
        --logo-height: 150px;
      }
    }

  }

  .ScheduleItemList {
    --header-height-adjust: 0px;
  }

  .ScheduleItemListItem {
    color: var(--white);

    &--closest {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
