.App--aliceinchains {
  background: url('./images/gallery-background.jpg') no-repeat;
  background-size: 100% auto;
  background-attachment: fixed;
  background-position: bottom;

  .Header {
    background: url('./images/footer-background.jpg') no-repeat;
    background-size: cover;
    background-position: center;

    color: var(--white);

    &__Logo {
      color: var(--white);
    }

    .CartCount__Icon {
      color: var(--white);
    }

    &__Buttons .Button {
      display: none;
    }
  }

  .ScheduleItemListItem {
    background: rgba(0, 0, 0, 0.2);

    &--closest {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .Footer {
    background: url('./images/footer-background.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 1;
  }
}
