.Button {
  margin: 10px 0;
  padding: 0 24px;

  min-width: 120px;
  font-size: var(--ui-size-normal);
  line-height: 36px;
  text-align: center;
  font-weight: normal;

  cursor: default;

  border: solid 1px var(--gray-30);
  color: var(--gray-40);
  border-radius: var(--control-border-radius);
  background-color: var(--white);

  &:hover {
    color: var(--gray-30);
  }

  &:active {
    color: var(--gray-50);
  }

  &--primary {
    background-color: var(--button-primary);
    border-color: var(--button-primary);
    color: var(--white);
  }

  &--primary:hover {
    background-color: var(--button-primary-hover);
    color: var(--white);
  }

  &--primary:active {
    background-color: var(--button-primary-active);
    color: var(--white);
  }

  &--highlighted {
    background-color: var(--stagepilot-green);
    border-color: var(--stagepilot-green);
    color: var(--white);
  }

  &--highlighted:hover {
    background-color: var(--green-tint);
    color: var(--gray-60);
  }

  &--highlighted:active {
    background-color: var(--stagepilot-green);
    color: var(--white);
  }

  &--dark {
    background-color: var(--gray-50);
    border-color: var(--gray-50);
    color: var(--white);
  }

  &--dark:hover {
    background-color: var(--gray-40);
    color: var(--white);
  }

  &--dark:active {
    background-color: var(--gray-60);
    color: var(--white);
  }

  &--tertiary {
    background-color: transparent;
    border-color: transparent;
    min-width: 0;
    padding: 0;
    text-decoration: underline;
  }

  &--danger {
    background-color: var(--button-danger);
    border-color: var(--button-danger);
    color: var(--white);
  }

  &--danger:hover {
    background-color: var(--button-danger-hover);
    color: var(--white);
  }

  &--danger:active {
    background-color: var(--button-danger-active);
    color: var(--white);
  }

  &--outline {
    border-color: var(--white);
    color: var(--white);
  }

  &--outline:hover {
    border-color: var(--white);
    color: var(--white);
  }

  &[disabled] {
    background-color: var(--gray-20);
    border-color: transparent;
  }
}
