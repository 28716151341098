.PostModal {
  position: relative;
  height: 100%;

  &__Body {
    text-align: center;

    @media (max-width: 699px) {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: 700px) {
      padding-bottom: 0px;
    }
  }

  &__Image {
    @media (min-width: 700px) {
      height: calc(100% - 80px);
    }
  }

  &__NavLink {
    display: block;
    line-height: 15px;
    position: absolute;
    top: calc(50% - 15px);

    padding: 0 9px;
    text-decoration: none;

    color: var(--white);

    &:active {
      color: var(--stagepilot-orange);
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__Download {
    background-color: var(--white);
    border: solid 1px var(--gray-30);
    border-radius: var(--control-border-radius);
    color: var(--gray-40);
    cursor: default;
    display: inline-block;
    font-size: var(--ui-size-normal);
    font-weight: normal;
    line-height: 36px;
    margin: 20px 0 0;
    min-width: 120px;
    padding: 0 24px;
    text-align: center;
    text-decoration: none;
  
    &:hover {
      color: var(--gray-30);
    }
  
    &:active {
      color: var(--gray-50);
    }
  
    &[disabled] {
      background-color: var(--gray-20);
      border-color: transparent;
    }
  }

  &__OrderPrints {
    margin-left: 20px;
  }
}
