.Page {
  position: relative;

  width: 100%;
  margin: 0;

  @media (min-width: 700px) {
    overflow: hidden;
    height: calc(100vh - 100px);

    display: flex;
    justify-content: center;
  }
}
