.RegisterFan {
  background: var(--gray-0);
  margin: var(--ui-space-normal) 0;
  padding: 0;

  border-radius: var(--box-border-radius);

  text-align: center;

  &__Icon {
    width: 90px;
    height: 90px;

    margin: 0 var(--ui-space-normal) var(--ui-space-normal);
  }

  &__Title {
    font-size: var(--ui-size-large);
    font-weight: bold;
    line-height: 1;
  }

  .RegisterFanStep__Title,
  &__Divider {
    display: none;
  }

  &--selfie {
    .RegisterFanStep {
      margin-top: var(--ui-space-small);

      &__Title {
        display: block;
      }
    }

    .RegisterFan__Divider {
      display: block;
      margin: 0 calc(var(--ui-space-normal) * 2);
    }
  }

  @media (min-width: 700px) {
    &__Title {
      font-size: 24px;
    }

    &__Body {
      font-size: 21px;
    }
  }
}

.RegisterFanForm {
  &__Field {
    width: calc(100% - var(--ui-space-normal) * 2);
    margin: 0 var(--ui-space-normal);

    &--marketing {
      justify-content: center;
      margin-bottom: var(--ui-space-normal);
    }
  }

  &__Input {
    width: 100%;
    display: block;
  }

  &__Submit {
    margin: 0 auto var(--ui-space-normal);
  }

  &__Input,
  &__Input:hover,
  &__Input:focus {
    &.Field {
      border-color: var(--gray-30);
    }
  }

  .Input__FileArea {
    padding: 0 var(--ui-space-normal);
  }

  .Input__FileButton {
    border-radius: 100px;
    flex-grow: 0;
    color: var(--default-text);
    margin: 0 auto;

    line-height: 42px;
    min-width: 140px;
  }

  .Input__FileLabel {
    text-decoration: underline;
    cursor: pointer;
    margin: var(--ui-space-normal) auto 0;
  }

  &__Legal {
    color: var(--gray-40);
    font-size: var(--ui-size-small);

    a {
      text-decoration: none;
      font-weight: bolder;
      color: var(--gray-70);
    }
  }

  .Button {
    flex-grow: 0.1;
  }

  @media(min-width: 700px) {
    &__Field {
      display: flex;
      flex-direction: row;
      align-items: start;
    }

    &__Input {
      flex: 1 1 auto;
      margin: 0.6rem 0.5rem 0.6rem 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.RegisterFanStep {
  margin: 0;
  padding: var(--ui-space-normal) var(--ui-space-normal) var(--ui-space-small);

  &__Title {
    text-transform: uppercase;
    font-size: var(--ui-size-small);
    letter-spacing: 0.92px;
    font-weight: 600;
    margin: 0;
    line-height: 1;
  }

  &__Selfie {
    margin-top: var(--ui-space-small);
    padding: calc(var(--ui-space-large) - var(--ui-space-small)) var(--ui-space-large);
    border: 1px solid var(--gray-30);
    border-radius: 6px;
    overflow: hidden;

    &--preview {
      padding: 0;
    }
  }

  &:last-of-type {
    padding: var(--ui-space-small) var(--ui-space-normal) var(--ui-space-normal);
  }

  @media(min-width: 700px) {
    &__Selfie {
      max-width: 438px;
    }
  }
}

.Input__File {
  display: inline-flex;
  flex-direction: column;
}

.Input__FileInputPreview {
  width: 100%;
  height: 100%;
  line-height: 0;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .Button {
    margin: 0;
    padding: 3px 0 0;

    min-width: auto;
    width: 22px;
    line-height: 17px;
    font-size: 24px;
    border-radius: 100px;

    position: absolute;
    top: var(--ui-space-small);
    right: var(--ui-space-small);
  }
}

.Input__FileControl {
  display: none;
}
