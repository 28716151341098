.EmailGate {
  &__Title {
    width: 75%;
    min-width: 300px;
    font-size: 28px;
    line-height: 38px;

    padding: 0;
    margin: 0 auto var(--ui-space-normal);
  }

  &__Subtitle {
    letter-spacing: 3px;
  }

  &__Field,
  &__Field--marketing {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;

    display: flex;
    width: 80%;
  }

  &__Field {
    input {
      width: 100%;

      + input {
        margin-left: 12px;
      }
    }
  }

  &__Buttons {
    margin: 28px auto 20px;

    .Button {
      margin: 0 16px;
    }
  }

  &__Legal {
    margin: 0;
    color: var(--gray-40);
    font-size: var(--ui-size-tiny);

    a {
      color: var(--gray-70);
      text-decoration: none;
    }
  }

  &--success {
    .EmailGate__Title {
      border: 0 solid var(--gray-40);
      border-width: 1px 0;
    }
  }
}
