$width: 241px;

.PostList__Main {
  height: 100%;
  padding-bottom: 60px;
}

.PostList__Item {
  text-decoration: none;
  line-height: 0;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
