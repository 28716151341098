body.no-scroll {
  overflow: hidden;
}

.Modal {
  z-index: 100;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: var(--modal-tint);

  display: flex;

  &__Content {
    background: var(--gray-10);
    border-radius: var(--box-border-radius);
    box-shadow: 0 1px 5px var(--modal-tint);

    @media (min-width: 700px) {
      min-width: 640px;
    }
  }

  &__Header {
    display: none;
  }

  &--fullScreen {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  &--centered {
    justify-content: center;
    align-items: center;

    display: flex;
    flex-direction: column;
  }

  &--fullScreen &__Header {
    z-index: 101;
    width: 100%;
    height: 60px;
    background-color: var(--gray-70);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--white);
  }

  &--fullScreen &__Content {
    flex-grow: 1;
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: center;
  }

  &--centered &__Content {
    padding: 40px 40px 23px;
    text-align: center;
  }

  &__Close {
    margin: 2rem;

    background: var(--gray-60);
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    cursor: pointer;
  }
}
