.EventSearch {
    position: absolute;
    background: linear-gradient(70deg, var(--stagepilot-teal), #4adff7);
    width: 100%;
    min-height: 100%;
}

.EventSearch__Body {
    position: relative;
    width: 510px;
    margin: 0 auto;

    @media (max-width: 699px) {
        width: 100%;
    }
}

.EventSearch__Logo {
    width: 100px;
    height: auto;

    position: absolute;
    top: -70px;
    left: calc(50% - 52px);

    .Base {
        fill: var(--white);
    }

    .Accent {
        fill: var(--white);
    }
}

.EventSearch__Form {
    background-color: var(--white);
    margin-top: 100px;
    border-radius: 6px;
    padding: 36px 36px 120px;
    min-height: 480px;

    @media (max-width: 699px) {
        border-radius: 0;
        min-height: auto;
    }
}

.EventSearch__Form hr {
    background-color: var(--gray-20);
    height: 1px;
    border: 0;
    margin: 36px -36px 0;
}

.EventSearch__Title {
    text-align: center;
}
.EventSearch__QuerySection {
    position: relative;
}

.EventSearch__Loading {
    position: absolute;
    right: 12px;
    top: 30px;
}

.EventSearch__Query,
.EventSearch__Query:focus {
    width: 100%;
    font-size: 18px;
    padding: 12px;
    border-color: var(--gray-20);
}

.EventSearch__Results {
    width: 100%;
    font-size: 18px;
    margin-top: 24px;
}

.EventSearch__Result {
    margin: 12px 0;
}

.EventSearch__Result a,
.EventSearch__Result a:visited {
    display: inline-block;
    text-decoration: none;
    line-height: 30px;
    padding: 0 12px;
    border-radius: 6px;
    background-color: transparent;
    color: var(--gray-70);

}

.EventSearch__Results a:hover {
    background-color: var(--teal-tint);
}

.EventSearch__Footer {
    position: absolute;
    bottom: 15px;
    color: var(--gray-50);

    p {
        margin: 3px;
    }

    a {
        color: var(--gray-50);
    }

    @media (max-width: 699px) {
        left: 0;
        right: 0;
        text-align: center;
    }
}