.Header {
  position: relative;

  background: var(--ui-header-background);
  color: var(--ui-header-text-color);
  border-bottom: var(--ui-header-border);

  max-height: var(--header-height);
  padding: 2px 9px;
  overflow: hidden;

  @media (min-width: 700px) {
    padding: 12px 30px;
  }

  &__Logo {
    --logo-height: 60px;
    display: block;

    font-size: var(--ui-size-huge);
    font-weight: 400;

    color: var(--gray-70);
    text-decoration: none;

    max-width: 500px;
    max-height: var(--logo-height);

    margin: 19px auto 10px;
    text-align: center;

    img {
      max-height: var(--logo-height);
      max-width: 100%;
      margin: 0 auto;
    }

    @media (min-width: 700px) {
      --logo-height: 95px;

      margin-bottom: 20px;
    }
  }

  &__Name,
  &__Date {
    text-align: center;

    margin: 0 auto 5px;

    line-height: 1.4;

    font-size: var(--ui-size-normal);
    font-weight: 400;

    @media (min-width: 700px) {
      margin-bottom: 3px;
      font-size: var(--ui-size-medium);
    }
  }

  &__Name {
    font-weight: 600;
  }

  &__Buttons {
    position: absolute;
    top: 5px;
    right: 0;

    display: flex;
    align-items: center;

    .Button {
      display: none;
    }

    @media (min-width: 700px) {
      top: 24px;
      right: 24px;

      .Button {
        display: block;
      }
    }
  }

  &__CheckoutIcon {
    padding: 0 10px;
  }
}
