.App--kassiashton {
  .Header {
    background: url('./images/gallery-header.jpg') no-repeat, #0e0e14;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    color: var(--white);

    &__Logo {
      opacity: 0;
      flex-grow: 1;
      width: 100%;
    }

    .CartCount__Icon {
      color: var(--white);
    }

    &__Buttons .Button {
      display: none;
    }
  }
}
