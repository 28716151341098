.GalleryGrid {
  margin: 0 auto;
  text-align: center;

  width: 796px;
  max-width: 100%;

  display: grid;
  grid-gap: 9px;
  padding: 9px;


  @media (min-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 700px) {
    grid-gap: 18px;
    padding: 18px;
  }
}
