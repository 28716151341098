.MarketingCheckbox {
  margin-top: 0.8rem;
  text-align: left;
  font-size: var(--ui-size-small);
  line-height: var(--ui-size-small);
  color: var(--gray-60);

  align-items: center;

  label {
    text-align: left;
  }

  input {
    margin: 0;
    width: 25px;
    min-width: auto;
  }
}
