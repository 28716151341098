.Thumbnail {
  background-color: var(--gray-20);
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  &--empty {
    .Thumbnail__Icon {
      width: 88px;
      height: 88px;
    }
  }

  // Missing image styling
  &--image {
    overflow: hidden;
    color: var(--gray-60);

    object-fit: cover;
    background-size: cover;
    background-position: center;
  }
}
