.Field {
  margin: 10px 0 0;
  padding: 0 10px;

  min-width: 120px;
  font-size: var(--ui-size-normal);
  line-height: 36px;

  border: solid 1px var(--gray-30);
  color: var(--default-text);
  border-radius: var(--box-border-radius);
  background-color: var(--white);

  &::placeholder {
    color: var(--gray-40);
  }

  &:hover {
    border-color: var(--gray-30);
  }

  &:focus {
    border-color: var(--gray-50);
  }

  &--required {
    border-color: var(--button-primary);

    &:hover {
      border-color: var(--button-primary-hover);
    }

    &:focus {
      border-color: var(--button-primary-active);
    }
  }

  &--error {
    border-color: var(--button-danger);

    &:hover {
      border-color: var(--button-danger-hover);
    }

    &:focus {
      border-color: var(--button-danger-active);
    }
  }

  &[disabled] {
    background-color: var(--gray-20);
    border-color: transparent;
  }
}
