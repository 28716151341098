.App--lainehardy {
  --ui-header-background: #000000;
  --ui-header-text-color: var(--white);
  --ui-page-background: var(--white);
  --ui-footer-background: #000000;

  .Header__Name {
    display: none;
  }
}
