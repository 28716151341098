.App--johncleese {
  --footer-height: 80px;

  background: url('./images/gallery-background.jpg') no-repeat, #FFFFFF;
  background-size: 60% auto;
  background-attachment: fixed;
  background-position: left -30% bottom var(--footer-height);

  .Header {
    background-color: #337EB5;
    color: var(--white);

    .CartCount__Icon {
      color: var(--white);
    }
  }

  .ScheduleItemListItem {
    background: rgba(255, 255, 255, 0.4);

    &--closest {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  .Footer {
    height: var(--footer-height);
    background: url('./images/footer-logo.png') no-repeat, url('./images/footer-background.png') no-repeat;
    background-size: auto 80%, cover;
    background-position: right 15px center, center;
    opacity: 1;
  }
}
