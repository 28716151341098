.App--konradco {
  --emerald: #006847;
  --jade: #019d75;

  background: var(--jade);

  .Header {
    background: var(--white);
    padding: 0;

    &__Logo {
      margin: 10px auto;
    }

    &__Name {
      background: var(--emerald);
      color: var(--white);

      padding: 15px;
      margin: 0;
      line-height: 1;
    }

    &__Date {
      display: none;
    }

    &__Buttons .Button {
      display: none;
    }
  }
}
