
.DropTarget {
  width: 100%;
  min-height: 100%;
  position: relative;

  &--hover * {
    pointer-events: none;
  }

  &__DropArea {
    position: absolute;
    background-color: var(--stagepilot-orange);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__Caption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}
