// sass-lint:disable no-ids, class-name-format

* {
  box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;

  margin: 0;
  padding: 0;
}

body {
  background-color: var(--white);
  color: var(--gray-80);
  font-family: var(--font-stack);
  font-size: var(--ui-size-normal);
}

p,
td,
th,
input,
button,
select {
  font-family: var(--font-stack);
  font-size: var(--ui-size-normal);
}

input:focus,
button:focus,
select:focus {
  outline: none;
}

h1 {
  font-weight: normal;
  font-size: 21px;
  line-height: 30px;
  margin: 0 0 12px;
}

p {
  margin: 6px 0;
}

hr {
  border-width: 0;
  border-top: 1px solid var(--gray-20);
}

.ApplicationError {
  width: 500px;
  margin: 200px auto;
  padding: 0 18px 3px;
  border-left: solid 3px var(--stagepilot-teal);
}

.hidden {
  display: none;
}

@media(max-width: 699px) {
  .desktop {
    display: none;
  }
}

@media(min-width: 700px) {
  .mobile {
    display: none;
  }
}
