.PasswordGate {
  z-index: 100;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: var(--modal-tint);

  display: flex;
  justify-content: center;
  align-items: center;

  &__Modal {
    padding: 40px;

    text-align: center;

    background: var(--gray-10);
    border-radius: var(--box-border-radius);
    box-shadow: 0 1px 5px var(--modal-tint);
    min-width: 300px;

    @media (min-width: 700px) {
      min-width: 640px;
    }
  }

  &__Title {
    font-size: 20px;
    margin: 0 auto var(--ui-space-small);
  }

  &__Field {
    margin-left: auto;
    margin-right: auto;

    text-align: center;

    width: 100%;

    @media (min-width: 700px) {
      width: 80%;
    }
  }

  &__Buttons {
    margin-top: var(--ui-space-normal);

    .Button {
      margin: 0 5px;
    }
  }
}
