.App--dc20 {
  --ui-footer-background: #091A2C;
  --ui-header-text-color: white;

  .Header {
    background: #091A2C;

    @media (min-width: 700px) {
      --header-height: 285px;

      &__Logo {
        --logo-height: 150px;
      }
    }
  }

  .ScheduleItemList {
    @media (min-width: 700px) {
      --header-height-adjust: 0px;
    }
  }

  .Footer {
    opacity: 1;
  }
}
