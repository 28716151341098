.App--nashvillesoccerclub {
  --ui-header-background: #1B315D;
  --ui-header-border: 15px solid #FCCF08;
  --ui-header-text-color: var(--white);
  --ui-footer-background: #1B315D;

  .Header {
    padding: 0;

    &__Logo {
      margin: 0 auto;
      width: 100%;
      line-height: 0;

      max-height: none;
      max-width: none;

      img {
        max-height: 200px;

        margin-left: -25%;
        max-width: 150%;

        @media (min-width: 700px) {
          margin: 0 auto;
          max-width: 100%;
        }
      }
    }

    &__Name,
    &__Date {
      display: none;
    }
  }

  .Footer {
    opacity: 1;
  }
}
