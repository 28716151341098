.App--robthomasac {
  .Header {
    background: var(--white);

    &__Logo {
      margin-top: 10px;

      --logo-height: 70px;

      @media (min-width: 700px) {
        --logo-height: 105px;
      }
    }
  }
}
