:root {
  --stagepilot-green: #50E3C2;
  --green-tint: #B9F8DA;
  --stagepilot-orange: #FFC144;
  --orange-tint: #FDEFA6;
  --stagepilot-red: #FA7268;
  --red-tint: #FFC5C0;
  --stagepilot-teal: #24BBEC;
  --teal-tint: #B2EEF8;

  --white: #FFFFFF;
  --gray-0: #F9F9F9;
  --gray-10: #F3F4F4;
  --gray-20: #E1E5E6;
  --gray-30: #C3C9CA;
  --gray-40: #939EA0;
  --gray-50: #727D7F;
  --gray-60: #5B6466;
  --gray-70: #444B4C;
  --gray-80: #2E3233;

  --button-primary: var(--stagepilot-teal);
  --button-primary-hover: #5BC4E6;
  --button-primary-active: #129DCA;

  --modal-tint: #444B4CCA;

  --default-text: var(--gray-70);


  --button-danger: var(--stagepilot-red);
  --button-danger-hover: #FF7F75;
  --button-danger-active: #FF6C61;

  --box-shadow: 0 2px 4px var(--gray-80);
  --light-box-shadow: 0 2px 4px var(--gray-20);

  --box-border-radius: 3px;
  --control-border-radius: 6px;
  --round-border-radius: 100px;

  --sidebar-width: 300px;
  --header-height: 160px;
  --footer-height: 40px;
  --cart-header-height: 60px;

  --ui-size-huge: 30px;
  --ui-size-large: 18px;
  --ui-size-medium: 16px;
  --ui-size-normal: 14px;
  --ui-size-small: 12px;
  --ui-size-tiny: 10px;

  --ui-space-large: 60px;
  --ui-space-normal: 20px;
  --ui-space-small: 11px;

  --font-stack: 'ProximaNova', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  --ui-header-background: var(--gray-0);
  --ui-header-border: none;
  --ui-header-text-color: var(--default-text);
  --ui-page-background: var(--white);
  --ui-footer-background: var(--gray-70);

  @media (min-width: 700px) {
    --header-height: 230px;
  }
}
