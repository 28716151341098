.App--skillet {
  --ui-footer-background: #242729;
  --ui-header-text-color: white;

  background: url('./images/gallery-background.jpg') no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center bottom;

  .Header {
    background: url('./images/gallery-header.jpg') no-repeat;
    background-size: cover;
    background-position: center;

    @media (min-width: 700px) {
      --header-height: 285px;

      &__Logo {
        --logo-height: 150px;
      }
    }
  }

  .ScheduleItemList {
    @media (min-width: 700px) {
      --header-height-adjust: 0px;
    }
  }

  .ScheduleItemListItem {
    color: var(--white);

    &__Time {
     color: var(--gray-30);
    }

    &--closest {
      background-color: #444B4C77;
    }
  }
}
