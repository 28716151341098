.Side {
  background: var(--white);
  position: relative;
  width: 100%;

  padding: var(--ui-space-normal);
  flex-basis: auto;

  &__Header {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 var(--ui-space-small);
  }

  &--focus {
    background-color: var(--gray-0);
  }

  &--hidden {
    display: none;
  }
}

@media (min-width: 700px) {
  .Side {
    width: calc(100% - var(--sidebar-width));
    height: 100%;
    padding-bottom: 80px;
    overflow: auto;

    &--small {
      width: var(--sidebar-width);
    }
  }
}
