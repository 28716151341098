.App--rileygreen {
  background: url('./images/gallery-background.jpg') no-repeat, #000000;
  background-size: cover;
  background-attachment: fixed;
  background-position: right top;

  .Header {
    background-color: #0D0D0D;
    color: var(--white);

    .CartCount__Icon {
      color: var(--white);
    }

    &__Buttons .Button {
      background-color: #E3BA76;
      border-color: #E3BA76;
    }
  }

  .ScheduleItemListItem {
    color: var(--white);
    background: rgba(46, 50, 51, 0.5);

    &__Time {
     color: var(--gray-30);
    }

    &--closest {
      background: rgba(91, 100, 102, 0.75);
    }
  }

  .Footer {
    background: #0D0D0D;
    opacity: 0.7;
  }
}

